<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('close')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <header class="bg-white border-b px-4 py-4 flex flex-wrap items-center">
              <h2 class="my-1 text-lg font-semibold text-gray-800">{{ title }}</h2>
            </header>
            <main class="p-4">
              <div class="flex flex-col">
                <p>Enter a configuration ID to compare against the current configuration in the space. Explore the <strong>Simulations</strong> tab on this space detail screen to find your desired configuration ID.</p>
                <label for="area" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
                  Configuration ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="number"
                      id="id-field"
                      v-model="enteredId"
                      name="configuration-id"
                      class="focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                      aria-describedby=""
                    >
                  </div>
                </div>
                <div class="text-red-600 italic text-xs font-medium mt-2">{{ validate(enteredId) ?? errorMessage }}</div><!-- errors -->
              </div>
            </main>
            <footer class="bg-white bottom-0 border-t px-4 py-3 flex flex-wrap items-baseline justify-between">
              <button
              class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              @click="$emit('close')">
              Cancel
              </button>
              <button v-if="isLoading" class="inline-flex items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
                  <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
                </svg>
                Adding configuration...
              </button>
              <button
              v-else
              @click="submit(enteredId)"
              form="id-field"
              :disabled="submitButtonDisabled"
              class="disabled:opacity-50 ml-1 inline-flex items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
              Add configuration
              </button>
            </footer>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, ref } from 'vue';
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { mapActions, useStore } from 'vuex';
import { isNullUndefinedOrEmpty } from '@/models/create-building';

export default {
  name: 'AddConfigurationForm',
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    spaceName: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const enteredId = ref(null);
    const submitButtonDisabled = computed(() => isNullUndefinedOrEmpty(enteredId.value) || validate(enteredId.value) != null);
    const title = computed(() => props.spaceName ? `Add a configuration to ${props.spaceName}` : 'Add a new configuration');
    const isLoading = ref(false);
    const errorMessage = ref(null);

    function validate(value) {
      return isNaN(value) ? 'Please enter a valid configuration' : null;
    }

    function createListOfAppendedConfigurationIds(idToAppend) {
      return [...store.getters.configurations.map(configuration => configuration.id), Number(idToAppend)];
    }

    return {
      submitButtonDisabled,
      enteredId,
      validate,
      title,
      isLoading,
      errorMessage,
      createListOfAppendedConfigurationIds
    };
  },
  methods: {
    async submit(id) {
      this.isLoading = true;
      this.errorMessage = null;
      const payload = {
        space_id: this.$route.params.spaceId,
        configuration_ids: this.createListOfAppendedConfigurationIds(id)
      };
      try {
        await this.addConfiguration(payload);
      } catch (error) {
        this.errorMessage = `Configuration ${id} does not exist${this.spaceName ? ` in ${this.spaceName}` : ''}.`;
      } finally {
        this.isLoading = false;
        if (this.errorMessage == null) {
          this.enteredId = null;
          this.$emit('close');
          this.$parent.$emit('configurationAdded', {configurationId: id});
        }
      }
    },
    ...mapActions(['addConfiguration']),
  }
};
</script>